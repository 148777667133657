<template>
  <div class="product-card">
    <b-skeleton-wrapper :loading="isProductsLoading">
      <template #loading>
        <ProductCardSkeleton />
      </template>
      <router-link class="product-card__title" :to="{ name: 'layout.product', params: { slug: product.slug } }">
        <div
          class="product-card__img"
          :class="{ 'no-photo': !imgUrl }"
          :style="{ backgroundImage: `url(${imgUrl ? imgUrl : require('@/assets/images/no-photo.jpeg')})` }"
        >
          <!--          <img v-if="imgUrl" :src="imgUrl" alt="product-image" />-->
          <!--          <img v-else src="@/assets/images/no-photo.jpeg" alt="No photo" />-->
          <!--          <div class="img-darkness"></div>-->
        </div>
      </router-link>
      <div class="product-card__rating">
        <Rating :rating="product.rating" />
        <div v-if="showFavoriteIcon" @click="deleteFromFavorites(product.id)" class="favorite-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="20"
            fill="currentColor"
            class="bi bi-heart-fill"
            viewBox="0 0 16 16"
          >
            <path fill-rule="evenodd" d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z" />
          </svg>
        </div>
      </div>
      <div class="product-card__title-wrapper">
        <router-link class="product-card__title" :to="{ name: 'layout.product', params: { slug: product.slug } }"
          >{{ product.name }}
        </router-link>
      </div>
      <span class="product-card__price"> {{ formatPrice(product.price) }} ₸</span>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import ProductCardSkeleton from './ProductCardSkeleton'
import Rating from '@/components/Rating'
import Api from '@/services/api'

export default {
  name: 'ProductCard',
  components: {
    ProductCardSkeleton,
    Rating,
  },

  props: {
    product: {
      type: Object,
      required: false,
      default: () => ({ name: '', images: [], price: '', rating: '0' }),
    },
    showFavoriteIcon: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data: () => ({
    isProductsLoading: true,
  }),

  computed: {
    imgUrl() {
      if (!this.product?.images?.length) return false
      return this.product.images[0].path
    },

    favorites() {
      return this.$store.state.products.favorites
    },

    isLogged() {
      const token = this.$store.state.token
      if (token) return true
      const lsToken = localStorage.getItem('uni_token')
      return !!lsToken
    },
  },

  mounted() {
    setTimeout(() => (this.isProductsLoading = false), 1500)
  },

  methods: {
    formatPrice(price) {
      return new Intl.NumberFormat('RU-ru').format(Number(price))
    },

    async deleteFromFavorites(id) {
      if (!this.isLogged) {
        this.$store.commit('products/REMOVE_PRODUCT_FROM_PRODUCTS', id)
        this.$nextTick(() => {
          localStorage.setItem('uni_favorites', JSON.stringify(this.favorites))
          return this.makeToast('Избранные', 'Товар был успешно удален из избранных', 'success', false)
        })
      } else {
        await this.wrapAndHandleRequest(() => Api.deleteFromFavorites({ productId: id }))
        this.$store.commit('products/REMOVE_PRODUCT_FROM_PRODUCTS', id)
        this.makeToast('Избранные', 'Товар был успешно удален из избранных', 'success', false)
      }
    },
  },
}
</script>
