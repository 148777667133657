<template>
  <section class="category">
    <CategoryPath :path="categoryPath" />
    <h4 class="category__title mt-3">{{ categoryTitle }}</h4>
    <div class="tip">Кол-во найденных товаров: {{ products.length }}</div>
    <div class="category__body mt-3">
      <b-row>
        <b-col md="3"><CategoryFilter @filterProduct="filterProducts" /></b-col>
        <b-col>
          <div v-if="products.length" class="category__sort mb-4">
            <span class="category__sort-item">Сортировать по:</span>
            <span
              v-for="item in sortList"
              :key="item.id"
              @click="setSelectedSort(item.id)"
              class="category__sort-item category__sort-item_black"
              :class="{ 'category__sort-item_selected': item.isSelected }"
            >
              {{ item.name }}
            </span>
          </div>
          <template v-if="products.length">
            <b-row>
              <b-col v-for="product in products" :key="product.id" md="4" sm="6">
                <ProductCard :product="product" class="mb-4" />
              </b-col>
            </b-row>
            <b-pagination
              v-model="page"
              :per-page="1"
              :total-rows="pagesCount"
              pills
              align="center"
              first-number
              last-number
            ></b-pagination>
          </template>
          <div v-else class="not-found">В категории {{ categoryTitle }} пока что отсутствуют товары</div>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import Api from '@/services/api'
import CategoryPath from '@/components/CategoryPath'
import CategoryFilter from '@/views/category/components/CategoryFilter'
import ProductCard from '@/components/Product/ProductCard'

export default {
  name: 'CategoryPage',
  components: {
    CategoryPath,
    CategoryFilter,
    ProductCard,
  },
  data: () => ({
    page: 1,
    query: {
      limit: '21',
      sortBy: '1',
    },
    pagesCount: 1,
    products: [],
    path: [],
    sortList: [
      {
        id: 1,
        name: 'Рекомендуемые',
        isSelected: false,
      },
      {
        id: 2,
        name: 'Топ товары',
        isSelected: false,
      },
      {
        id: 3,
        name: 'По алфавиту, А-Я',
        isSelected: false,
      },
      {
        id: 4,
        name: 'По алфавиту, Я-А',
        isSelected: false,
      },
      {
        id: 5,
        name: 'Цена, от дешевых до дорогих',
        isSelected: false,
      },
      {
        id: 6,
        name: 'Цена, от дорогих к дешевым',
        isSelected: false,
      },
      {
        id: 7,
        name: 'Дата, старое новое',
        isSelected: false,
      },
      {
        id: 8,
        name: 'Дата, новое старое',
        isSelected: false,
      },
    ],
  }),

  computed: {
    categoryPath() {
      const category = this.$store.state.categories.find((el) => el.slug === this.$route.params.slug)
      if (category) {
        return [{ name: category.name, to: { name: 'layout.category', params: { slug: category.slug } } }]
      }
      return []
    },

    selectedSort() {
      return this.sortList.find((el) => el.isSelected)
    },

    categoryTitle() {
      const category = this.$store.state.categories.find((el) => el.slug === this.$route.params.slug)
      return category ? category.name : ''
    },
  },

  async mounted() {
    this.page = this.$route.params.page
    await this.fetchProducts()
  },

  methods: {
    async fetchProducts() {
      let queries = this.formatObjectToQuery()
      queries += `category=${this.$route.params.slug}&page=${this.$route.params.page}`
      if (this.selectedSort) {
        queries += `&sortBy=${this.selectedSort.id}`
      }
      const { data, pagesCount } = await this.wrapAndHandleRequest(() => Api.filterProducts(queries))
      this.products = data
      this.pagesCount = pagesCount
    },

    async filterProducts(query) {
      let queries = `category=${this.$route.params.slug}&${query}page=1`
      const { data, pagesCount } = await this.wrapAndHandleRequest(() => Api.filterProducts(queries))
      this.products = data
      this.pagesCount = pagesCount
    },

    setSelectedSort(id) {
      this.sortList.forEach((el) => (el.id === id ? (el.isSelected = true) : (el.isSelected = false)))
      this.$nextTick(() => this.fetchProducts())
    },

    formatObjectToQuery() {
      return Object.entries(this.query).reduce((accumulate, query) => {
        return accumulate + `${query[0]}=${query[1]}&`
      }, '')
    },
  },

  watch: {
    async $route() {
      await this.fetchProducts()
    },

    page(newValue) {
      if (newValue == this.$route.params.page) return
      this.$router.replace({ name: 'layout.category', params: { slug: this.$route.params.slug, page: newValue } })
    },

    // query: {
    //   deep: true,
    //   handler() {
    //     this.$router.replace({ name: 'layout.category' })
    //   },
    // },
  },
}
</script>
