<template>
  <div class="filter">
    <div class="filter__title">Фильтр</div>
    <div v-for="filter in filters" :key="filter.id" class="filter__item-wrapper">
      <div v-if="filter.show" class="filter__item">
        <div @click="toggleFilter(filter.id)" class="filter__item-head">
          {{ filter.name }}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#66a91a"
            class="bi bi-chevron-down"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </div>
        <transition name="fade">
          <div v-show="filter.isToggled" class="filter__item-body">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-checkbox-group
                v-model="selected"
                :options="filter.filters"
                :aria-describedby="ariaDescribedby"
                name="flavour-2a"
                stacked
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </transition>
      </div>
    </div>

    <div class="filter__item-wrapper">
      <div class="filter__item">
        <div @click="toggleFilter(999)" class="filter__item-head">
          Цена
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="#66a91a"
            class="bi bi-chevron-down"
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </div>

        <transition name="fade">
          <div v-show="filters[0].isToggled" class="filter__item-body pb-2 pt-2">
            <b-input
              v-model.trim="priceMin"
              class="mb-3"
              placeholder="Минимальная цена"
              autocomplete="off"
              type="number"
            ></b-input>
            <b-input
              v-model.trim="priceMax"
              class="mb-3"
              placeholder="Максимальная цена"
              autocomplete="off"
              type="number"
            ></b-input>
            <div class="d-flex justify-content-center align-items-center flex-column">
              <b-button @click="setPricesFilter" variant="outline-success">Применить</b-button>
              <span
                v-show="isPriceFilterSelected"
                @click="resetPricesFilter"
                class="mt-2 cursor-pointer align-self-end"
              >
                Сбросить
              </span>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/services/api'

export default {
  name: 'CategoryFilter',
  data: () => ({
    priceMin: '',
    priceMax: '',
    selected: [],
    filters: [
      {
        name: 'Цена',
        id: 999,
        isToggled: false,
        filters: [],
        show: false,
      },
    ],
  }),

  computed: {
    isPriceFilterSelected() {
      return this.selected.find((el) => el.includes('price'))
    },
  },

  async mounted() {
    const { filters } = await this.wrapAndHandleRequest(Api.fetchFilters, true)
    const arr = []
    filters.forEach((el) => {
      const innerFilters = []
      if (el.filters && el.filters.length) {
        el.filters.forEach((filterItem) => {
          innerFilters.push({
            text: filterItem.name,
            value: `${el.key}=${filterItem.value}`,
          })
        })
      }
      arr.push({
        name: el.name,
        id: el.id,
        isToggled: false,
        filters: innerFilters,
        show: true,
      })
    })

    arr.forEach((el) => this.filters.push(el))
  },

  methods: {
    toggleFilter(id) {
      this.filters.forEach((el) => (el.id === id ? (el.isToggled = !el.isToggled) : (el.isToggled = false)))
    },

    setPricesFilter() {
      if (!this.priceMin.length && !this.priceMax.length) return
      if (this.priceMin.length && this.priceMax.length) {
        const arr = [`priceMin=${this.priceMin}`, `priceMax=${this.priceMax}`]
        this.selected = [...this.selected, ...arr]
      } else if (this.priceMin.length) {
        this.selected.push(`priceMin=${this.priceMin}`)
      } else if (this.priceMax.length) {
        this.selected.push(`priceMax=${this.priceMax}`)
      }
    },

    resetPricesFilter() {
      this.priceMin = ''
      this.priceMax = ''
      this.selected = this.selected.filter((el) => !el.includes('price'))
    },

    formatSelectedArrayToQuery() {
      const obj = this.selected.reduce((accumulate, filter) => {
        const [key, val] = filter.split('=')
        if (accumulate[key]) {
          if (key === 'priceMin' || key === 'priceMax') {
            accumulate[key] = val
          } else {
            accumulate[key] += `,${val}`
          }
        } else {
          accumulate[key] = val
        }
        return accumulate
      }, {})

      return Object.entries(obj).reduce((accumulate, query) => {
        return accumulate + `${query[0]}=${query[1]}&`
      }, '')
    },
  },

  watch: {
    selected() {
      const query = this.formatSelectedArrayToQuery()
      this.$emit('filterProduct', query)
    },
  },
}
</script>
